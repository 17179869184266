<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/weapphome/lottery' }">抽奖活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>抽奖活动详情</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="活动名称：">
                            <span class="form-span-text">{{ info.name }}</span>
                        </el-form-item>
                        <el-form-item label="活动对象：">
                            <el-tag v-if="info.user_scope === 0" size="mini">普通用户</el-tag>
                            <el-tag v-if="info.user_scope === 1" size="mini">新注册用户</el-tag>
                        </el-form-item>
                        <el-form-item label="背景图片：">
                            <Qnupload v-model="info.background_img" :sum="1" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="活动标题：">
                            <Qnupload v-model="info.title_img" :sum="1" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="按钮图片：">
                            <Qnupload v-model="info.button_img" :sum="1" :readonly='true' />
                        </el-form-item>
                        <el-form-item label="活动生效时间：">
                            <span class="form-span-text">{{ `${handleTime(info.show_start_time, 'time')} -
                                                            ${handleTime(info.show_end_time, 'time')}` }}</span>
                        </el-form-item>
                        <el-form-item label="活动抽奖时间：">
                            <span class="form-span-text">{{ `${handleTime(info.game_start_time, 'time')} -
                                                            ${handleTime(info.game_end_time, 'time')}` }}</span>
                        </el-form-item>
                        <el-form-item label="参与方式：">
                            <span v-if="info.total_type === 0" class="form-span-text">{{ ` 每人每天可抽${info.type_num}次`
                            }}</span>
                            <span v-else-if="info.total_type === 1" class="form-span-text">{{ `每人总共可抽${info.type_num}次`
                            }}</span>
                        </el-form-item>
                        <el-form-item label="分享增加次数：">
                            <template v-if="info.share_num > 0">
                                <el-tag size="mini">
                                    开启
                                </el-tag>
                                <span> 每人分享后可额外获得{{ info.share_num }}次</span>
                            </template>
                            <el-tag size="mini" v-else>关闭</el-tag>
                        </el-form-item>
                        <el-form-item label="分享规则：">
                            <el-card class="box-card">
                                <span class="form-span-text">{{ info.share_rule ? info.share_rule : '-' }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="未中奖提示语：">
                            <el-card class="box-card">
                                <span class="form-span-text">{{ info.empty_msg }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item label="奖品设置：">
                            <section class="specs__table">
                                <div class="specs__table-header">
                                    <section v-show="!info.have_empty" class="specs__table-title">
                                        <p>保底奖品</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>奖品名称</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>奖品类型</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>图片</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>奖品说明</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>奖品数量</p>
                                    </section>
                                    <section class="specs__table-title">
                                        <p>中奖概率</p>
                                    </section>
                                </div>
                                <div class="specs__table-content">
                                    <section v-for="(item, index) in info.gifts" :key="index">
                                        <div class="specs__table-cell">
                                            <div v-show="!info.have_empty" class="specs__table-title">
                                                <div class="specs__radio"
                                                    :class="{ 'specs__radio--inner': item.gift_floor === 1 }" />
                                            </div>
                                            <div class="specs__table-title">
                                                <p>{{ item.gift_name }}</p>
                                            </div>
                                            <div class="specs__table-title">
                                                <p>{{ item.gift_type === 1 ? '商品' : item.gift_type === 2 ? '优惠券' :
                                                    item.gift_type === 0 ? '未中奖' : '' }}
                                                </p>
                                            </div>
                                            <div class="specs__table-title">
                                                <el-image style="width: 22px; height: 22px; margin-right: 4px"
                                                    :src="item.gift_img" fit="contain" :preview-src-list="[item.gift_img]">
                                                </el-image>
                                            </div>
                                            <div class="specs__table-title">
                                                <p v-if="item.gift_type === 0"> - </p>
                                                <el-button v-else size="mini"
                                                    @click="seeDescription(item.gift_note)">查看说明</el-button>
                                            </div>
                                            <!-- 奖品数量 -->
                                            <div class="specs__table-title">
                                                <p>{{ item.gift_type === 0 ? '-' : item.gift_total }}</p>
                                            </div>
                                            <!-- 奖品中奖几率 -->
                                            <div class="specs__table-title">
                                                <p>{{ item.gift_prob }}%</p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </el-form-item>
                        <el-form-item label="抽奖规则：">
                            <el-card class="box-card">
                                <span class="form-span-text">{{ info.game_rule ? info.game_rule : '-' }}</span>
                            </el-card>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'
import help from '../../../custom/help';

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: true,
            game_uuid: '',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let game_uuid = this.$route.query.id
            if (game_uuid !== undefined) {
                this.game_uuid = game_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({ path: '/lesson/lesson' })    // 返回列表
                    }
                });
            }
        },
        // 门店详情
        async getinfo() {
            this.loading = true;
            try {
                const res = await this.get(`games/roulette/${this.game_uuid}/profile`);
                this.loading = false;
                if (res.code === 0) {
                    this.info = res.data;
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.loading = false;
                this.$message.error(error);
            }
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
        /**
         * 时间戳转时间字符串
         * @param {number} time 时间戳
         * @param {string} type 类型
         */
        handleTime(time, type = '') {
            return help.timestampToTime(time, type);
        },
        /**
         * 查看奖品说明
         * @param {string} info 奖品说明
         */
        seeDescription(info) {
            this.$alert(info, '奖品说明', {
                confirmButtonText: '确定',
            });
        },
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
